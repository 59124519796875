export const environment = {
  app: 'danceApp',
  production: true,
  api: '/api',
  hostUrl: 'https://app.danceunitesus.com',
  firebaseConfig: {
    apiKey: "AIzaSyALcfYAukbU7SD7mzkbxkrCAOOjyefs278",
    authDomain: "danceunitesus-staging.firebaseapp.com",
    projectId: "danceunitesus-staging",
    storageBucket: "danceunitesus-staging.appspot.com",
    messagingSenderId: "1079662257823",
    appId: "1:1079662257823:web:c43172b217ada09ce0a4aa"
  },
  wonderpushIos: {
    clientSecret:
      'e0108ff8443647c27a69426613b7ca5be7bd295cfee344cc71580a52d3be45f7',
    clientId: 'd22bfecf9b0ed12909d981a9a66d7aad82cf499a',
  },
  googleMapKey: 'AIzaSyDEvI_b9iqwj_YzueWIhYf3QJPyxQis5YQ',
  routers: {},
  payment: {
    stripePublickKey:
      'pk_live_51PDbhaI7FIQNTjpUJHf83pScsyNbueiRKls8qd7HclgXpw3oQ6Y5BFfqwPMvmZltfD8t946znHrXh0TKBZSqNle800tpQSwE8D',
    PERLS_MODE: 'payment',
    SUBSCRIPTION_MODE: 'subscription',
    EIGHT_PEARLS: {
      PRODUCT_ID: 'prod_Q3lF68wQ5xbjCk',
      PRICE_ID: 'price_1PDdizI7FIQNTjpUbtc5GOGH',
      pearls: 8,
    },
    FORTY_TWO_PEARLS: {
      PRODUCT_ID: 'prod_Q3lFDSi4LDF0FE',
      PRICE_ID: 'price_1PDdiaI7FIQNTjpUraV7iIRu',
      pearls: 42,
    },
    NINETY_PEARLS: {
      PRODUCT_ID: 'prod_Q3lEqPSbUnOpBD',
      PRICE_ID: 'price_1PDdi9I7FIQNTjpURJ98JME2',
      pearls: 90,
    },
    TWO_HUNDRES_PEARLS: {
      PRODUCT_ID: 'prod_Q3lDQboFs4yRk3',
      PRICE_ID: 'price_1PDdhLI7FIQNTjpUvcjvCAWK',
      pearls: 200,
    },
    FIVE_HUNDRED_AND_FORTY_PEARLS: {
      PRODUCT_ID: 'prod_Q3lDsLRYgQGZYX',
      PRICE_ID: 'price_1PDdgxI7FIQNTjpUBcOsQ6Yt',
      pearls: 540,
    },
    ONE_THOUSAND_TWO_HUNDRED_PEARLS: {
      PRODUCT_ID: 'prod_Q3lCGCUU9NbfQP',
      PRICE_ID: 'price_1PDdgMI7FIQNTjpU4ZDBZxvr',
      pearls: 1200,
    },
    ONE_MONTH_SUBSCRIPTION: {
      PRODUCT_ID: 'prod_Q3lJyQZxMUNaeU',
      PRICE_ID: 'price_1PDlN5I7FIQNTjpU9XbNg07N',
      period: '1 Month',
    },
    THREE_MONTHS_SUBSCRIPTION: {
      PRODUCT_ID: 'prod_Q3lJyQZxMUNaeU',
      PRICE_ID: 'price_1PDdn1I7FIQNTjpUTybxtayw',
      period: '3 Months',
    },
    ONE_YEAR_SUBSCRIPTION: {
      PRODUCT_ID: 'prod_Q3lJyQZxMUNaeU',
      PRICE_ID: 'price_1PDdn1I7FIQNTjpUvOLfun6D',
      period: '1 Year',
    },
  },
  mixpanelToken: '143f1bff0b3ccf61da56a78b5a92524c',
  discordServerId: '1219036408447238176',
  discordServerInviteLink: 'https://discord.gg/g4wQQYTSZQ',
  pipeDreamUrl: 'https://eojykymstdmqyi1.m.pipedream.net',
  discordServerLink: 'https://discord.com/channels/1219036408447238176',
};
